import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import CornerLogo from "../images/logo_sm.jpg"



const FooterSections = styled.div`

  margin: 0 auto;
  width: 100%;
  max-width: 936px;
  display: flex;

  @media (max-width: 660px) {
    flex-wrap: wrap;
  }
`

const Warnings = styled.div`
  width: 70%;

  @media (max-width: 660px) {
    width: 100%;
  }
`

const CornerLink = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 33px;
  background-image: url(${CornerLogo});
  background-position: center;
  background-size: 100%;
  margin-left: 20%;

  @media (max-width: 660px) {
    margin: auto;
  }
`

const currentYear = new Date()

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterContent {
      allMarkdownRemark(
        filter: { frontmatter: { whichPage: { eq: "footer" } } }
      ) {
        edges {
          node {
            frontmatter {
              icons {
                icon
                iconAltText
              }
            }
            html
          }
        }
      }
    }
  `)
  const { frontmatter, html } = data.allMarkdownRemark.edges[0].node
  const { icons } = frontmatter
  return (
    <footer
      style={{
        backgroundColor: `#1eaef1`,
        color: `#fff`,
        paddingBottom: `2rem`,
        paddingLeft: `1rem`,
        paddingRight: `1rem`,
        paddingTop: `3rem`,
      }}
    >
      <FooterSections>
      
        <div
          style={{
            display: `flex`,
            justifyContent: `flex-start`,
            marginBottom: `.2rem`,
            maxWidth: '50rem'
          }}
        >
          {icons.map((data, index) => {
            const { icon, iconAltText } = data
            return (
              <div style={{ width: `3.5rem`, marginRight: '1rem' }}>
                <img
                  src={icon}
                  alt={iconAltText}
                  key={`footer_icon_${index}`}
                />
              </div>
            )
          })}
        </div>
        <div>
            <p style={{marginTop:'1.6rem'}}>
              &copy; {currentYear.getFullYear()}
            </p>
        </div>
             
      </FooterSections>
      <FooterSections>      
        
        <Warnings dangerouslySetInnerHTML={{ __html: html }} />
        
        <CornerLink>
          <a 
            href="https://youtu.be/YhMJkdg2qVw" 
            style={{display: 'block', width:'100%', height:'100%'}}
            target= 'blank'
          >
            <div>
            </div>
          </a>
        </CornerLink>

      </FooterSections>
    </footer>
  )
}

export default Footer
