import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import HeaderBackground from "../images/header-background-1920x1080.jpg"

const HeaderStyles = styled.header`
  background-color: #e2e2e2;
  margin-bottom: 2rem;

  @media (min-width: 500px) {
    background-image: url(${HeaderBackground});
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
`

const HeaderInnerStyles = styled.div`
  margin: 0 auto;

  @media (min-width: 500px) {
    max-width: 50%;
    padding-bottom: 5rem;
    padding-top: 5rem;
  }

  @media (min-width: 896px) {
    max-width: 50%;
    padding-bottom: 9rem;
    padding-top: 9rem;
  }
`

const EmbedVideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  :after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderContent {
      allMarkdownRemark(
        filter: { frontmatter: { whichPage: { eq: "header" } } }
      ) {
        edges {
          node {
            frontmatter {
              youtubeID
              youtubeTitle
            }
          }
        }
      }
    }
  `)
  const { frontmatter } = data.allMarkdownRemark.edges[0].node
  const { youtubeID, youtubeTitle } = frontmatter
  const youtubeLink = `https://www.youtube.com/embed/${youtubeID}`
  return (
    <HeaderStyles>
      <HeaderInnerStyles>
        <EmbedVideoContainer>
          <iframe
            src={youtubeLink}
            title={youtubeTitle}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </EmbedVideoContainer>
      </HeaderInnerStyles>
    </HeaderStyles>
  )
}

export default Header
